

img {
    max-width: 100%; /* Ensures the image doesn't overflow its container */
    height: auto; /* Maintains the aspect ratio */
    display: block; /* Removes any inline spacing below the image */
    object-fit: cover; /* Adjusts how the image fits inside the container */
    box-sizing: border-box; /* Includes padding and borders in the width calculation */
  }

  p {
    line-height: 1.5em;
  }

.section1, .section2, .section3, .section4  {
    width: 100%; /* Ensure it takes up the full width of its parent */
    max-width: 100%; /* No wider than its parent container */
    box-sizing: border-box; /* Includes padding and border in width/height */
    overflow: hidden; /* Hides any overflowing content */
    padding: 40px 60px;
    padding-bottom: 70px;
    margin: 0 auto; /* Optional: Center the section if needed */
}

button {
    box-shadow: none;
    border: none;
    border-radius: 4px;
}

button:hover{
    cursor: pointer;
    box-shadow: none;

}
  


.section1 {
    background-color: #EEEEEE;

    display: flex;
    max-width: 100vw;
    justify-content: space-between; /* Optional, adds spacing between items */
    align-items: center; /* Aligns items vertically */
    gap: 3vw; /* Adds space between flex items (optional) */
    padding: 40px 60px;
    padding-bottom: 70px;
    box-sizing: border-box;



}
.section1 .article_wrapper{
    /* height: 40vh; */
    flex: 1;
}
.section1 .article_wrapper .title{
    color: #32698F;
    font-family: "Roboto", Sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;

}
.section1 .article_wrapper .paragraph-blue{
color: #32698F;
font-family: "Roboto", Sans-serif;
font-size: 17px;
font-weight: 600;
text-transform: none;
line-height: 1.3em;
}
.section1 .article_wrapper .paragraph-gray{
color: #7d7d7d;
font-family: "Roboto", Sans-serif;
font-size: 17px;
font-weight: 300;    
line-height: 1.5em;
opacity: 0.8;
                                     
}

.section1  .video_wrapper{
    flex:1;
    /* background-image: url('../../public/imgs/XTR.300&DWV.png'); */
    
    background-size: cover;
    background-position: 50%;
    cursor: pointer;
    text-align: center;

    
}

.section1 .elementor-video{
    max-width: 80%; /* Set the desired width */
    width: 100%;
    height: 320px; /* Ensures the height scales proportionally */
    margin: 0 auto;
    line-height: 1;
    border: none;

}

.section2{

    background-color: #00688E;
    width: 100%; /* Ensure it takes up the full width of its parent */
    max-width: 100%; /* No wider than its parent container */
    box-sizing: border-box; /* Includes padding and border in width/height */
    overflow: hidden; /* Hides any overflowing content */
    padding: 40px 60px;
    padding-bottom: 70px;
    margin: 0 auto; /* Optional: Center the section if needed */
}

.section2 > h2{
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    margin-bottom: 3vh;
    margin-left: 3vw;

}

.section2 > .article_white{
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.3em;
    max-width: 80%;
    margin-left: 3vw;

}

.wrapper-row {
    display: flex;
    justify-content: space-between; /* Optional: Creates space between columns */
    align-items: flex-start; /* Align items to the top (or change as needed) */
    gap: 10px; /* Optional: Adds space between the columns */

}
.tornato_gpt, .hydroprotector, .trapIt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tornato_gpt, .hydroprotector, .trapIt {
    flex: 1; /* Each column takes up equal space (1/3 of the container) */
  padding: 20px; /* Optional: Adds spacing inside each column */
  box-sizing: border-box; /* Includes padding in the width calculation */
    text-align: center;
}

.section2 .tornato_gpt h2{
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-weight: 600;
    line-height: 1.3em;
    
}

.section2 .tornato_gpt .article{
    text-align: center;
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 4vh;
    max-width: 70%;
}

.section2 .tornato_gpt button{
    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    fill: #00688E;
    color: #00688E;
    background-color: #FFFFFF;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    /* background-color: #69727d; */
    /* font-size: 15px; */
    padding: 12px 24px;
    border-radius: 3px;
    /* color: #fff; */
    /* fill: #fff; */
    text-align: center;
    transition: all .3s;
    box-shadow: none;
    width: auto;
    box-sizing: border-box;
    box-shadow: none;
}



.section2 .hydroprotector h2{
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-weight: 600;
    line-height: 1.3em;
    
}

.section2 .hydroprotector .article{
    text-align: center;
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 4vh;
    max-width: 70%;

}

.section2 .hydroprotector button{
    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    fill: #00688E;
    color: #00688E;
    background-color: #FFFFFF;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    /* background-color: #69727d; */
    /* font-size: 15px; */
    padding: 12px 24px;
    border-radius: 3px;
    /* color: #fff; */
    /* fill: #fff; */
    text-align: center;
    transition: all .3s;
    box-shadow: none;
    width: auto;
    box-sizing: border-box;
    box-shadow: none;
}


.section2 .trapIt h2{
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-weight: 600;
    line-height: 1.3em;
    
}

.section2 .trapIt .article{
    text-align: center;
    color: #FFFFFF;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 4vh;
    max-width: 70%;

}

.section2 .trapIt button{
    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    fill: #00688E;
    color: #00688E;
    background-color: #FFFFFF;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    /* background-color: #69727d; */
    /* font-size: 15px; */
    padding: 12px 24px;
    border-radius: 3px;
    /* color: #fff; */
    /* fill: #fff; */
    text-align: center;
    transition: all .3s;
    box-shadow: none;
    width: auto;
    box-sizing: border-box;
    box-shadow: none;
}

.section3{

    display: flex;
    flex-direction: column; /* Stacks children vertically */
    justify-content: center; /* Centers children vertically */
    align-items: center; /* Centers children horizontally */
    padding-top: 60px;
    padding-left: 70px;
}

.section3 img:nth-of-type(1){

    width: 20%;
    height: 23px;
}

.section3 p{

    font-family: "Roboto", Sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    color: #7d7d7d;
    opacity: 0.8;

}


/* section4 */

.section4{
    background-color: #CBCCD5;
    display: flex;
    padding-right: 70px;
}

.section4 img{
    width: 100%;
    height: 474px;
}
.section4 .wrapper1{
    margin-top:40px;
    width: 50%;

}

.section4 .wrapper0{
    width: 50%;
}
.section4 h2{
    
    color: #1A1C1D;
    font-family: "Roboto", Sans-serif;
    font-size: 24px;
    font-weight: 300;
    text-transform: capitalize;
    line-height: 26px;
    width: 70%;
    
    
}

.section4 p{
    color: #000000;
    font-family: "Roboto", Sans-serif;
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    margin-top: 40px;
    width: 85%;
    opacity: 0.7;
    
  
}

.section4 .wrapper2{
    margin-top: 40px;

}

.section4 span{
    color: #000000;
    font-family: "Roboto", Sans-serif;
    font-size: 17px;
    font-weight: 400;
    margin-top: 50px;
}
.section4 button{
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 23px;
    background-color: #32698f;
    padding: 10px 15px 10px 15px;
    text-decoration: none;
    color: #fff;
    border-radius:3px;
    fill: #fff;
    text-align: center;
    transition: all .3s;
    box-sizing: border-box;
    margin-left: 8px;

}

footer {
    background-color: #797979A8;
    display: flex;
    height:96px;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;

}

footer .wrapper1{
    display: flex;
    justify-content: space-between;
    gap: 3vw;
    align-items: center;
    
}
footer img:hover{
    cursor: pointer;
}

footer .wrapper1 .img1{
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 189px;
    height: 16px;
    /* line-height: 27px; */
   
    
}
footer .wrapper1 .img2{
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 101px;
    height: 36px;
    /* line-height: 27px; */
 
    
}

footer .links span{
    border-right: #000000 1px solid;
    padding: 0 8px;
}
footer .links span:hover{
   cursor: pointer;
}

footer .links span:last-child{
    
    border-right: #000000 1px solid;
   border-right: none;
}