.hero-swiper {
    width: 100%;
    height: 400px;
    position:relative;

  }
 
  .hero-swiper .swiper-pagination {
    padding-left:50px;
    position: absolute;
    bottom: 20px !important; /* Adjust as needed */
    left: auto !important;
    right: 20px !important; /* Adjust as needed */
    width: auto !important;
  }
 
  
  .hero-swiper .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: white;
    opacity: 0.5;
  }
  
  .hero-swiper .swiper-pagination-bullet-active {
    opacity: 1;
  }
  
  .hero-swiper .swiper-button-prev,
  .hero-swiper .swiper-button-next {
    color: rgba(238, 238, 238, 0.9);
    /* background: rgba(0, 0, 0, 0.5); */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-weight:bold;
  }
  
  .hero-swiper .swiper-button-prev::after,
  .hero-swiper .swiper-button-next::after {
    font-size: 24px;
  }
  
  .hero-swiper .swiper-button-prev {
    left: 20px;
  }
  
  .hero-swiper .swiper-button-next {
    right: 20px;
  }
  
  .hero-swiper .slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }
  
  .hero-swiper .slide-content {
    position: absolute;
    bottom: 125px;
    left: 0;
    right: 0;
    padding: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    transform: translateY(100%);
    transition: transform 0.5s ease-out;
    padding-left: 80px;
  }
  
  .hero-swiper .swiper-slide-active .slide-content {
    transform: translateY(0);
  }

  .slide-content button{
    background: transparent;
    color: inherit;
    text-decoration: none;
    border: 2px solid #fff;
    color: #fff;
    background: transparent;
    display: inline-block;
    display: inline-block;
    line-height: 1;
    /* background-color: #69727d; */
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all .3s;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 4vh;
  }

  .hero-swiper .slide-content-with-button{
    position: absolute;
    bottom: 90px;
    left: 0;
    right: 0;
    padding: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    transform: translateY(100%);
    transition: transform 0.5s ease-out;
    padding-left: 80px;
  }

  .hero-swiper .swiper-slide-active .slide-content-with-button {
    transform: translateY(0);
  }

  .slide-content-with-button button{
    background: transparent;
    color: inherit;
    text-decoration: none;
    border: 2px solid #fff;
    color: #fff;
    background: transparent;
    display: inline-block;
    display: inline-block;
    line-height: 1;
    /* background-color: #69727d; */
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all .3s;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 4vh;
  }

