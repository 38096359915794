.no-arrows input[type="number"] {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  
    /* Firefox */
    -moz-appearance: textfield;
  }
  